<script setup>
import Icon from '@/Components/Icon.vue';
import { useForm } from '@inertiajs/vue3';
import { computed, useSlots } from 'vue';
import ReactiveSlot from './Partials/ReactiveSlot.vue';
const props = defineProps({
    active: Boolean,

    activateMethod: String,
    activateUrl: String,

    deactivateMethod: String,
    deactivateUrl: String,

    activeText: String,
    inactiveText: String,
    activeIcon: String,
    inactiveIcon: String,

    iconRight: Boolean,
    disabled: Boolean,
});

const form = useForm({
    preserveScroll: true,
});

const method = computed(() => (props.active ? props.deactivateMethod : props.activateMethod));
const url = computed(() => (props.active ? props.deactivateUrl : props.activateUrl));
const icon = computed(() => (props.active ? props.activeIcon : props.inactiveIcon));

const sendForm = () => {
    form.submit(method.value, url.value);
};

const slots = useSlots();
</script>

<template>
    <button class="relative w-8 h-8" @click="sendForm" :disabled="disabled || form.processing">
        <ReactiveSlot :processing="form.processing">
            <div class="flex items-center justify-center w-full gap-4">
                <div v-if="icon" class="flex items-center justify-center w-4 h-4">
                    <Icon class="w-4 h-4" :icon="icon" />
                </div>
            </div>
        </ReactiveSlot>
    </button>
</template>
